import React from 'react';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import ContactUs from '../components/ContactUs/ContactUs';
import config from '../../data/SiteConfig';
import useAnalytics from '../hooks/useAnalytics';

const ContactUsPage = () => {
  const { trackEvent } = useAnalytics();
  trackEvent('View Contact Us Page');

  return (
    <Layout hideFooter>
      <div className="landing-container">
        <SEO metadata={{ title: config.contactUsTitle }} />
        <ContactUs />
      </div>
    </Layout>
  );
};

export default ContactUsPage;
