import React from 'react';
import { string } from 'prop-types';

import ErrorIcon from '../../Icons/ErrorIcon';

const ErrorMessage = ({ message }) => (
  <div className="col-xs col-md-12 error-section">
    <ErrorIcon className="error-icon" color="#E10000" />
    <p>{message}</p>
  </div>
);

ErrorMessage.propTypes = {
  message: string.isRequired,
};

export default ErrorMessage;
